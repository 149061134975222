import { lazy } from "react";

const NotFound = lazy(() => import("pages/notFound"));
const Home = lazy(() => import("pages/home"));
const About = lazy(() => import("pages/about"));
const Services = lazy(() => import("pages/services"));
const Portfolio = lazy(() => import("pages/portfolio"));
//@ts-ignore
const Product = lazy(() => import("pages/product"));
const Clients = lazy(() => import("pages/clients"));
const Vacancies = lazy(() => import("pages/vacancies"));


export interface IRoute {
  path: string;
  access?: string[] | "*";
  element: JSX.Element;
  inner?: IRoute[];
  index?: boolean;
  title: string;
}

const privateRoutes: IRoute[] = [];

const publicRoutes: IRoute[] = [
  {
    path: "*",
    title: "",
    element: <NotFound />,
  },
  {
    path: "/",
    title: "Home page",
    element: <Home />,
  },
  {
    path: "/about",
    title: "About",
    element: <About />,
  },
  {
    path: "/services",
    title: "Services",
    element: <Services />,
  },
  {
    path: "/portfolio",
    title: "Portfolio",
    element: <Portfolio />,
  },
  {
    path: "/portfolio/:id",
    title: "Product",
    element: <Product />,
  },
  {
    path: "/clients",
    title: "Clients",
    element: <Clients />,
  },
  {
    path: "/vacancies",
    title: "Vacancies",
    element: <Vacancies />,
  },
];

export { privateRoutes, publicRoutes };
