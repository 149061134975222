import {
  ArrowUp,
  Burger,
  Close,
  Facebook,
  Instagram,
  LinkedIn,
  Telegram,
} from "assets/images/icons";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useHooks from "./../../hooks/useHooks/index";
import { Logo } from "assets/images";
import i18next from "i18next";
import { storage } from "services";
const Header = () => {
  const { t } = useHooks();
  const [isOpen, setIsOpen] = useState(false);
  const [navbarOpen, setNavbarOpen] = useState(false);

  const toggleLanguageOptions = () => {
    setIsOpen(!isOpen);
  };
  const changeLang = (langCode: string) => {
		i18next.changeLanguage(langCode);
    window.location.reload();
	};
  let lang = storage.get("i18nextLng");
  useEffect(() => {
    if (navbarOpen) {
      document.body.classList.add("navbar-open");
    } else {
      document.body.classList.remove("navbar-open");
    }
  }, [navbarOpen]);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };
  
  return (
    <>
      <div className="header">
        <Link  onClick={scrollToTop}  to="/" className="logo">
          <img src={Logo} alt="Logo" />
        </Link>
        <div className="header_links">
          <Link  onClick={scrollToTop}  to={"/about"}>{t("About")}</Link>
          <Link  onClick={scrollToTop}  to={"/services"}>{t("Services")}</Link>
          <Link  onClick={scrollToTop}  to={"/portfolio"}>{t("Portfolio")}</Link>
          <Link  onClick={scrollToTop}  to={"/clients"}>{t("Clients")}</Link>
          <Link  onClick={scrollToTop}  to={"/vacancies"}>{t("Vacancies")}</Link>
          <div className="lang_wrapper" onClick={toggleLanguageOptions}>
            <div className="lang_select">
              <div className="lang_items">
                {!isOpen && <span>{lang}</span>}
                {isOpen && (
                  <>
                    <span onClick={() => changeLang("en")}>EN</span>
                    <span onClick={() => changeLang("ru")}>RU</span>
                    <span onClick={() => changeLang("uz")}>UZ</span>
                  </>
                )}
              </div>
              {isOpen ? (
                <span className="arrow_right">
                  <ArrowUp />
                </span>
              ) : (
                <ArrowUp />
              )}
            </div>
          </div>
        </div>
        <a className="header_number" href="tel:+998952405111">
          <span>+99895</span>
          <span>2405111</span>
        </a>
        <button onClick={() => setNavbarOpen(true)} className="burger_icon">
          <Burger />
        </button>
      </div>

      <div className={`mobile-menu ${navbarOpen ? "isOpen" : ""}`}>
        <button
          className="close_icon"
          onClick={() => {
            setNavbarOpen(false);
          }}
        >
          <Close />
        </button>
        <div className="mobile_links">
          <Link  onClick={scrollToTop}  to={"/about"}>{t("About")}</Link>
          <Link  onClick={scrollToTop}  to={"/services"}>{t("Services")}</Link>
          <Link  onClick={scrollToTop}  to={"/portfolio"}>{t("Portfolio")}</Link>
          <Link  onClick={scrollToTop}  to={"/clients"}>{t("Clients")}</Link>
          <Link  onClick={scrollToTop}  to={"/vacancies"}>{t("Vacancies")}</Link>
          <div className="mobile_lang">
            <span className={`${lang === "uz" ? "active-lang" : "" }`} onClick={() => changeLang("uz")}>UZ</span>
            <span className={`${lang === "en" ? "active-lang" : "" }`} onClick={() => changeLang("en")}>EN</span>
            <span className={`${lang === "ru" ? "active-lang" : "" }`} onClick={() => changeLang("ru")}>RU</span>
          </div>
        </div>

        <div className="social_links">
          <a target="_blank" href="https://www.facebook.com/hypernova.uz">
            <Facebook />
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/hypernova-uz/about/?viewAsMember=true"
          >
            <LinkedIn />
          </a>
          <a target="_blank" href="https://www.instagram.com/hypernova.uz/">
            <Instagram />
          </a>
          <a target="_blank" href="https://t.me/HyperNova_Inc">
            <Telegram />
          </a>
        </div>
      </div>
    </>
  );
};

export default Header;
