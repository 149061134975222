import { Logo } from "assets/images";
import {
  Email,
  Facebook,
  Instagram,
  LinkedIn,
  Location,
  Tel,
  Telegram,
  ToTop,
} from "assets/images/icons";
import { useHooks } from "hooks";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useHooks();
  const handleTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };
  return (
    <div className="footer">
      <div className="footer_top">
        <button onClick={handleTop}>
          <ToTop />
        </button>
      </div>
      <div className="footer_center">
        <div className="footer_links">
          <div className="page_links">
            <Link  onClick={scrollToTop}  to={"/about"}>{t("About")}</Link>
            <Link  onClick={scrollToTop}  to={"/services"}>{t("Services")}</Link>
            <Link  onClick={scrollToTop}  to={"/portfolio"}>{t("Portfolio")}</Link>
            <Link  onClick={scrollToTop}  to={"/clients"}>{t("Clients")}</Link>
            <Link  onClick={scrollToTop}  to={"/vacancies"}>{t("Vacancies")}</Link>
          </div>
          <div className="emails_socials">
          <div className="email_phone">
            <div>
              <p>{t("Email")}</p>
              <a href="mailto:hypernova.uz@gmail.com">
                <Email />
                <p>HyperNova@gmail.com</p>
              </a>
            </div>
            <div>
              <p>{t("Phone")}</p>
              <a href="tel:+998952405111">
                <Tel />
                <p>+ 998 95 240 51 11</p>
              </a>
            </div>
          </div>
          <div className="social_links">
            <a target="_blank" href="https://www.facebook.com/hypernova.uz">
              <Facebook />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/hypernova-uz/about/?viewAsMember=true"
            >
              <LinkedIn />
            </a>
            <a target="_blank" href="https://www.instagram.com/hypernova.uz/">
              <Instagram />
            </a>
            <a target="_blank" href="https://t.me/HyperNova_Inc">
              <Telegram />
            </a>
          </div>
          </div>
        </div>
        <div className="footer_map">
            <iframe
            title="map"
              src="https://yandex.uz/map-widget/v1/?ll=69.226769%2C41.281451&mode=search&oid=172169172463&ol=biz&sctx=ZAAAAAgBEAAaKAoSCcueBDbnUVFAERGPxMvTp0RAEhIJmuyfpwGD0z8R98lRgCiYwT8iBgABAgMEBSgKOABA31BIAWoCdXqdAc3MTD2gAQCoAQC9AXtOi8nCAQbvq9SwgQWCAgloeXBlcm5vdmGKAgCSAgCaAgxkZXNrdG9wLW1hcHM%3D&sll=69.226769%2C41.281451&sspn=0.019054%2C0.008595&text=hypernova&z=16"
              width="560"
              height="400"
              frameBorder={1}
              allowFullScreen={true}
            ></iframe>
            <div>
              <Location/>
              <p>{t("Toshkent shahar, Chilonzor tumani, Gagarin ko'chasi 25")}</p>
            </div>

        </div>
      </div>
      <div className="footer_bottom">
        <p>{t("© 2023 Hypernova. All rights reserved")}</p>
        <Link onClick={scrollToTop}   to={"/"}>
          <img src={Logo} alt="Logo" />
        </Link>
      </div>
    </div>
  );
};

export default Footer;
