import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="notfound-page">
      Not found 
    </div> 
  ); 
};

export default NotFound;
