import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import RoutesWrapper from "routes";
import useStore from "store";
import "assets/styles/index.scss";
import ScrollToHashElement from "components/scrollHashElement";
import useLenis from "hooks/useLenis";
import "assets/styles/index.scss";

function App() {
  useLenis();
  const {
    setLoadingStatus,
    system: { loadingStatus },
  } = useStore();
  const [count, setCount] = useState(0);

  useEffect(() => {
    const duration = 5000; // 5 seconds
    const endValue = 100;
    const startValue = 0;
    const increment = (endValue - startValue) / (duration / 10); // calculate increment per 10ms

    const timer = setInterval(() => {
      setCount((prevCount) => {
        const newCount = prevCount + increment;
        if (newCount >= endValue) {
          clearInterval(timer);
          return endValue;
        }
        return newCount;
      });
    }, 9); // update every 10ms

    return () => clearInterval(timer);
  }, []);

  loadingStatus
    ? (document.body.style.overflow = "auto")
    : (document.body.style.overflow = "hidden");

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoadingStatus(true);
    }, 5000); // 5000 milliseconds = 5 seconds
    return () => {
      clearTimeout(timeout);
    };
  }, [setLoadingStatus]);

  return (
    <>
      <ScrollToHashElement />

      {!loadingStatus ? (
        <div className={`loader-video`}>
          <p>HYPERNOVA</p>
          <span> {String(Math.floor(count)).padStart(3, "0")}</span>
        </div>
      ) : (
        <></>
      )}
      <ToastContainer />
      <RoutesWrapper />
    </>
  );
}

export default App;
